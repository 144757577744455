import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Image from 'components/image';
import Leader from 'components/leader';
import Markdown from 'components/markdown';
import { Container, Row } from 'components/layout';

import Block from './Block';
import s from './WideImage.scss';

export default class WideImage extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    imagePosition: PropTypes.oneOf(['center', 'right']),
    cta: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }

  static defaultProps = {
    heading: '',
    text: '',
    image: {},
    imagePosition: 'center',
  }

  render() {
    const { heading, text, cta, image, imagePosition, blocks } = this.props;

    return (
      <div className={s.wideImage}>
        {heading && (
          <Leader heading={heading}>
            <Markdown cols={1} source={text} className={s.wideImage__markdown} />
            {cta.url && <Button to={cta.url} color="transparent">{cta.text}</Button>}
          </Leader>
        )}

        <Container>

          {image &&
            <div className={s('wideImage__imageWrap', imagePosition)}>
              <div className={s.wideImage__image}>
                <Image
                  src={image.src}
                  alt={image.alt}
                  width={image.width}
                  height={image.height}
                />
              </div>
            </div>
          }

        </Container>

        <Container>
          {blocks.length > 0 && (
          <Row>
            {blocks.map(block => (
              <div className={s.wideImage__block} key={block.heading}>
                <div className={s.wideImage__blockInner}>
                  <Block
                    heading={block.heading}
                    text={block.text}
                  />
                </div>
              </div>
            ))}
          </Row>
        )}
        </Container>
      </div>
    );
  }
}
