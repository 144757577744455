import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, Row } from 'components/layout';
import Markdown from 'components/markdown';
import Image from 'components/image';
import Leader from 'components/leader';

import s from './LogoSet.scss';

export default class LogoSet extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    logos: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    ),
    footer: PropTypes.string,
  }

  static defaultProps = {
    heading: null,
    text: null,
    logos: [],
    footer: {},
  }

  render() {

    const {
      heading,
      text,
      logos,
      footer,
    } = this.props;

    return (
      <div className={s.logoSet}>
        <Leader className={s.logoSet__intro} centered heading={heading} text={text} />
        <Container>
          <Row>
            {logos.map(logo => (
              <div className={s.logoSet__logo} key={logo.src}>
                <Image
                  src={logo.src}
                  alt={logo.alt || ''}
                  width={logo.width}
                  height={logo.height}
                  transition="none"
                />
              </div>
            ))}
          </Row>
          <div className={s.logoSet__footer}>
            <Markdown source={footer} className={s.logoSet__footerInner} />
          </div>
        </Container>
      </div>
    );
  }
}
