/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import s from './Circles.scss';

export default class Circles extends PureComponent {

  static propTypes = {
    theme: PropTypes.oneOf(['light', 'dark']),
  }

  render() {
    const { theme } = this.props;

    return (
      <div className={s(s.circles, theme)}>
        {Array(3).fill(0).map((_, i) => (
          <div key={i} className={s.circles__div} />
        ))}
      </div>
    );
  }
}
