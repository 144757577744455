import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';

import s from './Callout.scss';

const Callout = ({ theme, heading, cta }) => (
  <div className={s('callout', theme)}>
    <div className={s.callout__inner}>
      <h3 className={s.callout__heading}>{heading}</h3>
      <Button to={cta.url} color={cta.color || 'red'} icon={cta.icon}>
        {cta.text}
      </Button>
    </div>
  </div>
);

Callout.propTypes = {
  heading: PropTypes.string,
  theme: PropTypes.oneOf(['grey', 'white']),
  cta: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
};

Callout.defaultProps = {
  theme: 'grey',
};

export default Callout;
