import React from 'react';
import PropTypes from 'prop-types';
import s from './Block.scss';

const Block = ({ heading, text }) => (
  <div className={s.block}>
    <h2 className={s.block__heading}>{heading}</h2>
    <p className={s.block__text}>{text}</p>
  </div>
);

Block.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Block;
