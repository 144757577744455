import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Callout from 'components/callout'
import Leader from 'components/leader'
import { Container, Row } from 'components/layout'
import ImageSetItem from './ImageSetItem'

import s from './ImageSet.scss'

export default class ImageSet extends Component {
  static propTypes = {
    headingLarge: PropTypes.bool,
    heading: PropTypes.string,
    text: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        caption: PropTypes.object,
        orientation: PropTypes.string,
      })
    ),
    boxHeading: PropTypes.string,
    boxCta: PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }

  render() {
    const {
      headingLarge,
      heading,
      text,
      images,
      boxHeading,
      boxCta,
    } = this.props

    return (
      <section className={s.imageSet}>
        {heading && (
          <Leader
            headingTag={headingLarge ? 'h1' : 'h2'}
            heading={heading}
            text={text}
          />
        )}
        <Container>
          <Row>
            {images &&
              images.map(image => (
                <div className={s.imageSet__item} key={image.src}>
                  <ImageSetItem
                    caption={image.caption}
                    image={image.src}
                    width={image.width}
                    height={image.height}
                    orientation={image.orientation}
                  />
                </div>
              ))}
          </Row>
          {boxHeading && (
            <div className={s.imageSet__box}>
              <Callout cta={boxCta} heading={boxHeading} />
            </div>
          )}
        </Container>
      </section>
    )
  }
}
