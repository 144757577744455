import React from 'react';
import PropTypes from 'prop-types';

import s from './ImageComparisonControls.scss';

const ImageComparisonControls = ({ label, onChangeImage, active }) => (
  <div className={s('imageComparisonControls')}>
    <div className={s.imageComparisonControls__inner}>
      <span className={s.imageComparisonControls__label}>{label}</span>
      <button
        title="small"
        className={s('imageComparisonControls__button', 'small', { [s.active]: active === 0 })}
        onClick={() => { onChangeImage(0); }}
      />

      <button
        title="large"
        className={s('imageComparisonControls__button', 'large', { [s.active]: active === 1 })}
        onClick={() => { onChangeImage(1); }}
      />
    </div>
  </div>
);

ImageComparisonControls.propTypes = {
  onChangeImage: PropTypes.func,
  label: PropTypes.string,
  active: PropTypes.number,
};

ImageComparisonControls.defaultProps = {
  onChangeImage: () => null,
};

export default ImageComparisonControls;
