import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ImageGrid from 'components/image-grid';

export default class ImageGridModule extends Component {

  static propTypes = {
    images: PropTypes.arrayOf(
      PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
          contentType: PropTypes.string,
          details: PropTypes.shape({
            image: PropTypes.shape({
              width: PropTypes.number,
              height: PropTypes.number,
            }),
          }),
        }),
        description: PropTypes.string,
      }),
    ).isRequired,
  }

  render() {

    const {
      images,
    } = this.props;

    return (
      <ImageGrid items={images.map(image => ({
          src: image.file.url,
          width: image.file.details.image.width,
          height: image.file.details.image.height,
        }))}
      />
    );
  }
}

export const query = graphql`
  fragment imageGridModule on ContentfulModuleImageGrid {
    images {
      ...image
    }
  }
`;
