import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';
import { Container } from 'components/layout';
import IconTable from 'components/icon-table';

import s from './ImageColumnSwappable.scss';

export default class ImageColumnSwappable extends PureComponent {

  static propTypes = {
    images: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    ).isRequired,
    reversed: PropTypes.bool,
    heading: PropTypes.string,
    text: PropTypes.string,
    content: PropTypes.array,
  }

  state = {
    activeImage: 0,
  }

  onUpdate = (i) => {
    this.setState({
      activeImage: i,
    });
  }

  render() {
    const {
      images,
      reversed,
      heading,
      text,
      content,
    } = this.props;

    const { activeImage } = this.state;

    const enoughImages = images.length === content.length + 1;

    return (
      <div
        className={s(
          'imageColumn',
          { [s.reversed]: reversed },
        )}
      >
        <Container>
          <div className={s.imageColumn__row}>
            <div className={s.imageColumn__imageCol}>
              <div className={s.imageColumn__imageWrap}>
                {images.length > 0 &&
                  <div className={s.imageColumn__wipe}>
                    <Image
                      transition="wipe"
                      src={images[enoughImages ? activeImage : 0].src}
                      alt={images[enoughImages ? activeImage : 0].alt}
                      width={images[0].width}
                      height={images[0].height}
                    />
                  </div>
                }
              </div>
            </div>

            <div className={s.imageColumn__content}>

              <div className={s.imageColumn__contentInner}>
                <h1 className={s.imageColumn__heading}>{heading}</h1>
                {text && <p className={s.imageColumn__text}>{text}</p>}
                {content.length > 0 &&
                  <div className={s.imageColumn__children}>
                    <IconTable rows={content} onUpdate={this.onUpdate} />
                  </div>
                }
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
