import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { TimelineLite } from 'gsap';

import Circles from 'components/circles';
import Button from 'components/button';
import ViewportEnter from 'components/viewport-enter';

import s from './Cta.scss';

export default class Cta extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    cta: PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
      color: PropTypes.oneOf(['red', 'transparent', 'white']),
    }),
    backgroundImage: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    padding: PropTypes.oneOf(['small', 'medium', 'large']),
    containBackground: PropTypes.bool,
    circles: PropTypes.oneOf(['Enable', 'Disable']),
  }

  static defaultProps = {
    heading: '',
    text: null,
    cta: {},
    backgroundImage: {},
    padding: 'medium',
    containBackground: false,
  }

  animate = () => {
    const items = this.content.childNodes;

    const t = new TimelineLite();

    t.staggerFromTo(items, 1, { y: 40, autoAlpha: 0 }, { y: 0, autoAlpha: 1 }, 0.2);
  }

  render() {
    const {
      heading,
      text,
      cta,
      backgroundImage,
      padding,
      containBackground,
      circles,
    } = this.props;

    return (
      <ViewportEnter onEnter={this.animate}>
        <div className={s(s.cta, { containBackground })}>
          <div
            className={s(s.cta__background, padding)}
            style={{ backgroundImage: `url(${_get(backgroundImage, 'src')})` }}
          >
            <div className={s.cta__container}>
              <div ref={(el) => { this.content = el; }}>
                <h2 className={s.cta__heading}>{heading}</h2>
                {text && <p className={s.cta__text}>{text}</p>}
                {cta.url && (
                  <div className={s.cta__ctas}>
                    <Button size="large" to={cta.url} color={cta.color} className={s.cta__button}>
                      {cta.text}
                    </Button>
                  </div>
                )}
              </div>
            </div>

            {circles === 'Enable' && (
              <Circles theme="light" />
            )}
          </div>
        </div>
      </ViewportEnter>
    );
  }
}
