import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ViewportEnter from 'components/viewport-enter';
import { ImageTransition } from '.';

export default class Image extends Component {

  state = {
    loaded: false,
  }

  static propTypes = {
    src: PropTypes.string.isRequired,
    src2x: PropTypes.string,
    placeholder: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    transition: PropTypes.oneOf(['fade', 'scale', 'wipe', 'none']),
  };

  static defaultProps = {
    placeholder: require('assets/images/placeholder.gif'), // transparent gif
    transition: 'fade',
  }

  onEnter = () => {
    this.loadImage();
  }

  loadImage = () => {
    const img = document.createElement('img');

    img.onload = () => this.setState({ loaded: true });
    img.src = this.props.src;
  }

  render() {
    const {
      src,
      src2x,
      alt,
      width,
      height,
      transition,
      placeholder,
      ...rest
    } = this.props;

    const { loaded } = this.state;

    // eslint-disable-next-line no-nested-ternary
    const srcSet = !src2x ? undefined :
      loaded ? `${src} 720w, ${src2x} 1080w` : `${placeholder} 720w, ${placeholder} 1080w`;

    const loadedSrc = loaded ? src : placeholder;

    if (transition !== 'none') {

      return (
        <ViewportEnter threshold={0} onEnter={this.onEnter}>
          <ImageTransition
            type={transition}
            active={loaded}
            width={width}
            height={height}
          >
            <img
              src={loadedSrc}
              srcSet={srcSet}
              alt={alt}
              width={width}
              height={height}
              {...rest}
            />
          </ImageTransition>
        </ViewportEnter>
      );
    }
    return (
      <ViewportEnter threshold={0} onEnter={this.onEnter}>
        <img
          src={loadedSrc}
          srcSet={srcSet}
          alt={alt}
          width={width}
          height={height}
          {...rest}
        />
      </ViewportEnter>
    );
  }
}
