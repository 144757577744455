import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { graphql } from 'gatsby';
import PriceWithFeatures from 'components/price-with-features';

export default class PriceWithFeaturesModule extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    features: PropTypes.array,
    priceHeading: PropTypes.string,
    price: PropTypes.string,
    priceText: PropTypes.string,
    priceCtaText: PropTypes.string,
    priceCtaUrl: PropTypes.string,
  }

  render() {
    const {
      heading,
      text,
      features,
      priceHeading,
      price,
      priceText,
      priceCtaText,
      priceCtaUrl,
    } = this.props;

    return (
      <PriceWithFeatures
        heading={heading}
        text={_get(text, 'text')}
        features={features}
        priceHeading={priceHeading}
        price={price}
        priceText={priceText}
        priceCta={{
          text: priceCtaText,
          url: priceCtaUrl,
        }}
      />
    );
  }
}

export const query = graphql`
  fragment priceWithFeaturesModule on ContentfulModulePriceWithFeatures {
    heading: title
    text: body {
      text: body
    }
    priceHeading: priceTitle
    price
    priceText: priceBody
    priceCtaText
    priceCtaUrl
    features {
      ...submoduleList
    }
  }
`;
