import React, { cloneElement, PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import Submodules from 'containers/submodules';

import Callout from 'components/callout';
import { Container, Row } from 'components/layout';
import Image from 'components/image';
import Leader from 'components/leader';

import s from './IconSet.scss';

export default class IconSet extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    iconBlocks: PropTypes.array,
    imagePlacement: PropTypes.oneOf(['top', 'middle']),
    columnCount: PropTypes.oneOf([1, 2, 3]),
    boxHeading: PropTypes.string,
    boxCta: PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }

  static defaultProps = {
    heading: null,
    text: null,
    image: null,
    iconBlocks: null,
    imagePlacement: null,
    columnCount: null,
    boxHeading: null,
    boxCta: null,
  }

  render() {

    const {
      heading,
      text,
      image,
      iconBlocks,
      imagePlacement,
      columnCount,
      boxHeading,
      boxCta,
    } = this.props;

    const imageEl = image && image.src ? (
      <div className={s.iconSet__image}>
        <Image src={image.src} alt={image.alt || ''} width={image.width} height={image.height} />
      </div>
    ) : null;

    return (
      <div className={s('iconSet', { [s.hasIntroImage]: imagePlacement === 'top', [s.hasIntroText]: text })}>
        <div className={s.iconSet__introWrap}>
          <Leader className={s.iconSet__leader} centered heading={heading} text={text} />
          {imageEl && (
            <Container>
              { imagePlacement === 'top' ? (cloneElement(imageEl, { transition: 'fade' })) : (cloneElement(imageEl, { transition: 'none' }))}
            </Container>
          )}
        </div>

        <Container>

          <Row>
            {iconBlocks && iconBlocks.map(submodule => (
              <div
                key={_get(submodule, 'heading')}
                className={s(
                  s.iconSet__iconBlock,
                  {
                    [s.iconSet__iconBlockHalf]: columnCount === 2,
                    [s.iconSet__iconBlockThird]: columnCount === 3,
                  },
                )}
              >
                <div className={s.iconSet__iconBlockInner}>
                  <Submodules list={[submodule]} />
                </div>
              </div>
            ))}
          </Row>
          {boxHeading && (
            <div className={s.iconSet__box}>
              <Callout
                cta={boxCta}
                heading={boxHeading}
                text={boxCta.text}
              />
            </div>
          )}
        </Container>
      </div>
    );
  }
}
