import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, pathname, article, htmlAttributes }) => {
    return (
        <StaticQuery
          query={query}
          render={({
            site: {
              siteMetadata: {
                defaultTitle,
                defaultDescription,
                siteUrl,
                defaultImage,
                twitterUsername,
              },
            },
          }) => {

            // Check to add protocol
            if (image != null && !image.startsWith('http:') && !image.startsWith('https:')) {
              image = 'https:' + image
            }

            const seo = {
              title: title || defaultTitle,
              description: description || defaultDescription,
              image: `${image || defaultImage}`,
              url: `${siteUrl}${pathname || "/"}`,
              htmlAttributes: htmlAttributes || {},
            }
            
            return (
              <>
                <Helmet title={seo.title} htmlAttributes={seo.htmlAttributes}>
                  <meta name="description" content={seo.description} />
                  <meta name="image" content={seo.image} />
                  {seo.url && <meta property="og:url" content={seo.url} />}
                  {(article ? true : null) && (
                    <meta property="og:type" content="article" />
                  )}
                  {seo.title && <meta property="og:title" content={seo.title} />}
                  {seo.description && (
                    <meta property="og:description" content={seo.description} />
                  )}
                  {seo.image && <meta property="og:image" content={seo.image} />}
                  <meta name="twitter:card" content="summary_large_image" />
                  {twitterUsername && (
                    <meta name="twitter:creator" content={twitterUsername} />
                  )}
                  {seo.title && <meta name="twitter:title" content={seo.title} />}
                  {seo.description && (
                    <meta name="twitter:description" content={seo.description} />
                  )}
                  {seo.image && <meta name="twitter:image" content={seo.image} />}
                </Helmet>
              </>
            )
          }}
        />
      )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
