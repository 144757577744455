import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

// Add new submodules as imports here
import IconBlock from './IconBlock';

/**
 * List of Contentful modules that can be rendered as components. Each key comes from the GraphQL
 * schema, and the value is the corresponding component.
 * @type Object.<String, Function>
 */
const MODULES = {
  ContentfulIconBlock: IconBlock,
};

/**
 * Component to render a series of Contentful modules as individual components.
 */
export default class Modules extends Component {

  static propTypes = {
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  render() {
    const { list } = this.props;

    return (
      <Fragment>
        {list.map((module, index) => {
          // eslint-disable-next-line no-underscore-dangle
          const moduleName = module.__typename;
          const Module = MODULES[moduleName] || (() => null);

          return (
            <Module
              // eslint-disable-next-line react/no-array-index-key
              key={`${moduleName}-${index}`}
              {...module}
            />
          );
        })}
      </Fragment>
    );
  }
}

export const query = graphql`
  fragment submoduleList on Node {
    __typename
    ...iconBlock
  }
`;
