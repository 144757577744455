import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Leader from 'components/leader';
import Button from 'components/button';
import Carousel, { CarouselItem } from 'components/carousel';

import { Card, CardSetFooter } from './';

import s from './CardSet.scss';

export default class CardSet extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    cta: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
    cardLayout: PropTypes.oneOf(['Carousel', '3 Columns']),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        imageSrc: PropTypes.string,
        heading: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
    footerImages: PropTypes.array,
    footerText: PropTypes.string,
    footerCta: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }

  static defaultProps = {
    items: undefined,
  }

  render() {
    const {
      heading,
      text,
      cta,
      cardLayout,
      items,
      footerImages,
      footerText,
      footerCta,
    } = this.props;

    return (
      <div className={s('cardSet', { [s.columns]: cardLayout !== 'Carousel' })}>
        {heading && (
          <Leader heading={heading} text={text} className={s('cardSet__leader', { [s.noCta]: !cta.url })}>
            {cta.url &&
              <Button color="transparent" to={cta.url}>
                {cta.text}
              </Button>
            }
          </Leader>
        )}
        {items.length > 0 &&
          <Fragment>
            {cardLayout === 'Carousel' ? (
              <Carousel>
                {items.map((item, i) => (
                  <CarouselItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.heading}-${i}`}
                    heading={item.heading}
                    image={item.imageSrc}
                    text={item.text}
                  />
                ))}
              </Carousel>
            ) : (
              <div className={s.cardSet__wrapper}>
                <div className={s.cardSet__container}>
                  <ol className={s.cardSet__inner}>
                    {items.map((item, i) => (
                      <li
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${s.cardSet__item}-${i}`}
                        className={s.cardSet__item}
                      >
                        <Card
                          index={i + 1}
                          heading={item.heading}
                          image={item.imageSrc}
                          text={item.text}
                        />
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            )}
          </Fragment>
        }

        { footerText && <CardSetFooter images={footerImages} text={footerText} cta={footerCta} /> }
      </div>
    );
  }
}
