import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import { graphql } from 'gatsby'

import Submodules from 'containers/submodules'
import ImageColumn from 'components/image-column'
import AccordionList from 'components/accordion-list'

export default class ImageModule extends Component {
  static propTypes = {
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
        contentType: PropTypes.string,
        details: PropTypes.shape({
          image: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
          }),
        }),
      }),
      description: PropTypes.string,
    }).isRequired,
    isRowReversed: PropTypes.bool.isRequired,
    verticalAlignment: PropTypes.bool.isRequired,
    heading: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    content: PropTypes.array,
    contentColumnCount: PropTypes.oneOf([1, 2]),
    accordionList: PropTypes.array,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    contentSize: PropTypes.oneOf(['default', 'even', 'wide']),
    children: PropTypes.node,
    boxHeading: PropTypes.string,
    boxCtaIcon: PropTypes.string,
    boxCtaText: PropTypes.string,
    boxCtaUrl: PropTypes.string,
  }

  static defaultProps = {
    heading: '',
    text: {},
    content: [],
    contentColumnCount: 1,
    ctaText: 'Learn more',
    ctaUrl: null,
    contentSize: 'wide',
    children: null,
  }

  render() {
    const {
      image,
      // might be better to base this off image position ie. oneOf(['left', 'right'])) instead?
      isRowReversed,
      // this is a boolean prop, should this rather be OneOf(['top', 'center']) ?
      verticalAlignment,
      heading,
      text,
      content,
      contentColumnCount,
      accordionList,
      ctaText,
      ctaUrl,
      contentSize,
      children,
      boxHeading,
      boxCtaIcon,
      boxCtaText,
      boxCtaUrl,
    } = this.props

    return (
      <ImageColumn
        reversed={isRowReversed}
        alignTop={verticalAlignment}
        image={{
          src: _get(image, 'file.url'),
          alt: _get(image, 'description'),
          width: _get(image, 'file.details.image.width'),
          height: _get(image, 'file.details.image.height'),
        }}
        heading={heading}
        link={ctaUrl}
        linkText={ctaText}
        text={_get(text, 'text')}
        contentSize={contentSize}
        childColumns={contentColumnCount === 2}
        boxHeading={boxHeading}
        boxCta={{
          icon: boxCtaIcon,
          text: boxCtaText,
          url: boxCtaUrl,
        }}
      >
        {/* Does this work? */}
        {content &&
          content.map(submodule => (
            <div key={_get(submodule, 'heading')}>
              <Submodules list={[submodule]} />
            </div>
          ))}
        {accordionList && (
          <AccordionList
            list={accordionList.map(item => ({
              heading: item.heading,
              text: _get(item.text, 'text'),
              tooltip: item.tooltip,
            }))}
          />
        )}
        {children}
      </ImageColumn>
    )
  }
}

export const query = graphql`
  fragment imageModule on ContentfulModuleImage {
    image {
      ...image
    }
    isRowReversed
    verticalAlignment
    heading: title
    text: body {
      text: body
    }
    content {
      ...submoduleList
    }
    contentColumnCount
    accordionList {
      ...headingAndText
    }
    ctaText
    ctaUrl
    contentSize
    boxHeading: boxTitle
    boxCtaIcon
    boxCtaText
    boxCtaUrl
  }
`
