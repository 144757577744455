import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { graphql } from 'gatsby';

import kebabCaseIcon from 'utils/kebab-case-icon';
import ImageColumnSwappable from 'components/image-column-swappable';
import IconTable from 'components/icon-table';

export default class ImageSwapModule extends Component {

  static propTypes = {
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
        contentType: PropTypes.string,
        details: PropTypes.shape({
          image: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
          }),
        }),
      }),
      description: PropTypes.string,
    }).isRequired,
    isRowReversed: PropTypes.bool.isRequired,
    heading: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    content: PropTypes.array,
    additionalImages: PropTypes.array,
  }

  static defaultProps = {
    heading: '',
    text: {},
    content: [],
    additionalImages: [],
  }

  render() {

    const {
      image,
      isRowReversed,
      heading,
      text,
      content,
      additionalImages,
    } = this.props;

    const images = additionalImages.map(additionalImage => ({
      src: _get(additionalImage, 'file.url'),
      alt: _get(additionalImage, 'description'),
      width: _get(additionalImage, 'file.details.image.width'),
      height: _get(additionalImage, 'file.details.image.height'),
    }));

    images.unshift({
      src: _get(image, 'file.url'),
      alt: _get(image, 'description'),
      width: _get(image, 'file.details.image.width'),
      height: _get(image, 'file.details.image.height'),
      first: true,
    });

    return (
      <ImageColumnSwappable
        reversed={isRowReversed}
        images={images}
        heading={heading}
        text={_get(text, 'text')}
        content={content.map(iconRow => ({
          heading: _get(iconRow, 'heading'),
          text: _get(iconRow, 'text.text'),
          icon: kebabCaseIcon(iconRow.icon),
        }))}
      />
    );
  }
}

export const query = graphql`
  fragment imageSwapModule on ContentfulModuleImageSwappable {
    image {
      ...image
    }
    isRowReversed
    heading: title
    text: body {
      text: body
    }
    content {
      ...submoduleList
    }
    additionalImages {
      ...image
    }
  }
`;
