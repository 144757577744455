import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { graphql } from 'gatsby';

import LogoSet from 'components/logo-set';

export default class LogosModule extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    footnote: PropTypes.shape({
      text: PropTypes.string,
    }),
    logos: PropTypes.arrayOf(
      PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
          contentType: PropTypes.string,
          details: PropTypes.shape({
            image: PropTypes.shape({
              width: PropTypes.number,
              height: PropTypes.number,
            }),
          }),
        }),
        description: PropTypes.string,
      }),
    ),
  }

  render() {
    const {
      heading,
      text,
      logos,
      footnote,
    } = this.props;

    return (
      <LogoSet
        heading={heading}
        text={_get(text, 'text')}
        logos={logos.map(logo => ({
          src: _get(logo, 'file.url'),
          alt: _get(logo, 'description'),
          width: _get(logo, 'file.details.image.width'),
          height: _get(logo, 'file.details.image.height'),
        }))}
        footer={_get(footnote, 'text')}
      />
    );
  }
}

export const query = graphql`
  fragment logosModule on ContentfulModuleLogos {
    heading: title
    text: body {
      text: body
    }
    logos {
      ...image
    }
    footnote {
      text: footnote
    }
  }
`;
