import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import _get from 'lodash/get';

import CardSet from '../../components/card-set';

export default class CardSetModule extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    cardLayout: PropTypes.oneOf(['Carousel', '3 Columns']),
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
        heading: PropTypes.string,
        text: PropTypes.shape({
          text: PropTypes.string,
        }),
      }),
    ),
    footerImages: PropTypes.array,
    footerText: PropTypes.string,
    footerCtaText: PropTypes.string,
    footerCtaUrl: PropTypes.string,
  }

  render() {

    const {
      heading,
      text,
      ctaText,
      ctaUrl,
      cardLayout,
      cards,
      footerImages,
      footerText,
      footerCtaText,
      footerCtaUrl,
    } = this.props;

    return (
      <CardSet
        heading={heading}
        text={_get(text, 'text')}
        cta={{
          text: ctaText,
          url: ctaUrl,
        }}
        cardLayout={cardLayout}
        items={cards.map(card => ({
          imageSrc: _get(card.image, 'file.url'),
          heading: card.heading,
          text: _get(card.text, 'text'),
        }))}
        footerText={footerText}
        footerImages={footerImages && footerImages.map(image => ({
          src: _get(image, 'file.url'),
          alt: _get(image, 'description'),
          width: _get(image, 'file.details.image.width'),
          height: _get(image, 'file.details.image.height'),
        }))}
        footerCta={{
          text: footerCtaText,
          url: footerCtaUrl,
        }}
      />
    );
  }
}

export const query = graphql`
  fragment numberedCard on ContentfulNumberedCard {
    image {
      ...image
    }
    heading: title
    text: body {
      text: body
    }
  }

  fragment cardSetModule on ContentfulModuleCardSet {
    heading: title
    text: body {
      text: body
    }
    ctaText
    ctaUrl
    cardLayout
    cards {
      ...numberedCard
    }
    footerImages {
      ...image
    }
    footerText: footerBody
    footerCtaText
    footerCtaUrl
  }
`;
