import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { graphql } from 'gatsby';

import Callout from 'components/callout';
import ImageColumnVariant from 'components/image-column-variant';

export default class ImageVariantModule extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    cta2Text: PropTypes.string,
    cta2Url: PropTypes.string,
    textPlacement: PropTypes.oneOf(['Left', 'Right']),
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
        contentType: PropTypes.string,
        details: PropTypes.shape({
          image: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
          }),
        }),
      }),
      description: PropTypes.string,
    }).isRequired,
    boxHeading: PropTypes.string,
    boxCtaText: PropTypes.string,
    boxCtaUrl: PropTypes.string,
  }

  static defaultProps = {
    heading: '',
    text: {},
    ctaText: 'Learn more',
    ctaUrl: null,
    cta2Text: 'Learn more',
    cta2Url: null,
    textPlacement: 'Left',
    boxHeading: null,
    boxCtaText: 'Learn more',
    boxCtaUrl: null,
  }

  render() {

    const {
      heading,
      text,
      ctaText,
      ctaUrl,
      cta2Text,
      cta2Url,
      textPlacement,
      image,
      boxHeading,
      boxCtaText,
      boxCtaUrl,
    } = this.props;

    return (
      <ImageColumnVariant
        image={{
          src: _get(image, 'file.url'),
          alt: _get(image, 'description'),
          width: _get(image, 'file.details.image.width'),
          height: _get(image, 'file.details.image.height'),
        }}
        heading={heading}
        text={_get(text, 'text')}
        ctas={[
          { text: ctaText, url: ctaUrl },
          { text: cta2Text, url: cta2Url },
        ]}
        reversed={textPlacement === 'Right'}
      >
        {boxHeading &&
          <Callout
            heading={boxHeading}
            cta={{ text: boxCtaText, url: boxCtaUrl }}
          />
        }
      </ImageColumnVariant>
    );
  }
}

export const query = graphql`
  fragment imageVariantModule on ContentfulModuleImageVariant {
    heading: title
    text: body {
      text: body
    }
    ctaText
    ctaUrl
    cta2Text
    cta2Url
    textPlacement
    image {
      ...image
    }
    boxHeading: boxTitle
    boxCtaText
    boxCtaUrl
  }
`;
