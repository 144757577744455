/* eslint-disable jsx-a11y/media-has-caption */
import 'lib/DrawSVGPlugin';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TimelineLite, Linear, Power4 } from 'gsap';

import s from './Step.scss';

export default class Step extends PureComponent {

  static propTypes = {
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    index: PropTypes.number,
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    src: PropTypes.string,
  }

  circleRef = React.createRef();
  headingRef = React.createRef();
  descriptionRef = React.createRef();
  descriptionHeight = 0;
  alreadyInit = false;

  state = {
    isMobile: false,
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  componentWillReceiveProps(props) {
    const { isActive } = this.props;
    const { isMobile } = this.state;

    if (props.isActive !== isActive && props.isActive && !isMobile) {
      this.timeline.play();
    } else if (props.isActive !== isActive && !props.isActive && !isMobile) {
      this.timeline.pause(0, true);
      this.timeline.remove();
    }
  }

  onResize = () => {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    this.setState({ isMobile });
    this.handleDescriptionHeight();

    if (isMobile && this.timeline) {
      this.alreadyInit = false;
      this.timeline.pause(0, true);
      this.timeline.remove();
    } else if (!isMobile && !this.alreadyInit) {
      this.alreadyInit = true;
      this.initTimeline();
    }
  }

  handleDescriptionHeight = () => {
    const description = this.descriptionRef.current;

    if (description) {
      const { height } = description.getBoundingClientRect();

      this.descriptionHeight = height;
    }
  }

  initTimeline = () => {
    const { start, end } = this.props;
    const circle = this.circleRef.current;
    const heading = this.headingRef.current;
    const description = this.descriptionRef.current;

    this.timeline = new TimelineLite({ paused: true });

    if (!circle || !heading || !description) {
      return;
    }

    const duration = Number(end) - Number(start);

    this.timeline.addLabel('start');

    this.timeline.fromTo(
      circle,
      duration,
      { drawSVG: '0%', rotation: -90, transformOrigin: 'center' },
      { drawSVG: '100%', ease: Linear.easeNone },
    );

    this.timeline.fromTo(
      heading,
      0.2,
      { opacity: 0.3 },
      { opacity: 1, ease: Power4.easeInOut },
      'start',
    );

    this.timeline.fromTo(
      description,
      0.2,
      { opacity: 0.3 },
      { opacity: 1, ease: Power4.easeInOut },
      'start',
    );
  }

  renderCircle = index => (
    <div className={s.step__colCircle}>
      <div className={s.step__group}>
        <svg viewBox="0 0 50 50" className={s.step__svg}>
          <circle
            className={s.step__background}
            r="23"
            cx="25"
            cy="25"
            strokeWidth={2}
            fill="none"
            stroke="#dedede"
          />

          <circle
            ref={this.circleRef}
            r="23"
            cx="25"
            cy="25"
            stroke="#fa3350"
            strokeWidth={2}
            fill="none"
          />
        </svg>

        <div className={s.step__number}>{index}</div>
      </div>
    </div>
  );

  render() {
    const { heading, description, start, index, onClick, src } = this.props;

    return (
      <button
        key={index}
        className={s.step}
        onClick={() => onClick(Number(start))}
      >
        <div className={s.step__wrapper}>
          {this.renderCircle(index + 1)}

          <div className={s.step__details}>
            <h3
              ref={this.headingRef}
              className={s.step__heading}
            >
              {heading}
            </h3>

            <p
              ref={this.descriptionRef}
              className={s.step__description}
            >
              {description}
            </p>
          </div>
        </div>

        <img
          className={s.step__image}
          src={src}
          alt={heading}
        />
      </button>
    );
  }
}
