import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { graphql } from 'gatsby';

import ImageComparison from 'components/image-comparison';

export default class ProductComparisonModule extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    price: PropTypes.string,
    defaultImageLeft: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }).isRequired,
    defaultColorLeft: PropTypes.oneOf(['light', 'dark']).isRequired,
    defaultImageRight: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }).isRequired,
    defaultColorRight: PropTypes.oneOf(['light', 'dark']).isRequired,
    optionalImageLeft: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    optionalColorLeft: PropTypes.oneOf(['light', 'dark']),
    optionalImageRight: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    optionalColorRight: PropTypes.oneOf(['light', 'dark']),
    optionHeading: PropTypes.string,
    optionalPrice: PropTypes.string,
  }

  static defaultProps = {
    heading: 'Compare',
    text: null,
    price: null,
    optionalImageLeft: null,
    optionalColorLeft: 'light',
    optionalImageRight: null,
    optionalColorRight: 'dark',
    optionHeading: null,
    optionalPrice: null,

  }

  render() {

    const {
      heading,
      text,
      price,
      defaultImageLeft,
      defaultColorLeft,
      defaultImageRight,
      defaultColorRight,
      optionalImageLeft,
      optionalColorLeft,
      optionalImageRight,
      optionalColorRight,
      optionHeading,
      optionalPrice,
    } = this.props;

    const defaultImages = {
      left: {
        src: _get(defaultImageLeft, 'file.url'),
        color: defaultColorLeft,
        price,
      },
      right: {
        src: _get(defaultImageRight, 'file.url'),
        color: defaultColorRight,
        price,
      },
    };

    const optionalImages = {
      left: {
        src: _get(optionalImageLeft, 'file.url'),
        color: optionalColorLeft,
        price: optionalPrice || price,
      },
      right: {
        src: _get(optionalImageRight, 'file.url'),
        color: optionalColorRight,
        price: optionalPrice || price,
      },
    };

    const images = [defaultImages];

    if (optionalImages.left.src) {
      images.push(optionalImages);
    }

    return (
      <ImageComparison
        height={1165}
        width={1860}
        images={images}
        heading={heading}
        text={_get(text, 'text')}
        optionHeading={optionHeading}
      />
    );
  }
}

export const query = graphql`
  fragment productComparisonModule on ContentfulModuleProductComparison {
    heading: title
    text: body {
      text: body
    }
    price
    defaultImageLeft {
      ...image
    }
    defaultColorLeft
    defaultImageRight {
      ...image
    }
    defaultColorRight
    optionalImageLeft {
      ...image
    }
    optionalColorLeft
    optionalImageRight {
      ...image
    }
    optionalColorRight
    optionHeading: optionTitle
    optionalPrice
  }
`;
