/* eslint-disable jsx-a11y/media-has-caption, react/no-array-index-key */
import React, { PureComponent, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { TimelineLite, Power4 } from 'gsap';

import Leader from 'components/leader';
import Button from 'components/button';
import ViewportEnter from 'components/viewport-enter';

import s from './VideoSteps.scss';

export default class VideoSteps extends PureComponent {

  static propTypes = {
    children: PropTypes.node,
    heading: PropTypes.string,
    description: PropTypes.string,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    video: PropTypes.string,
    poster: PropTypes.string,
    headingCentered: PropTypes.bool,
    videoPosition: PropTypes.oneOf(['Top', 'Right']),
  }

  state = {
    currentTime: 0,
    isMobile: false,
  }

  headerRef = React.createRef();
  groupRef = React.createRef();
  videoRef = React.createRef();
  alreadyInit = false;

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);

    const video = this.videoRef.current;

    if (video) {
      video.addEventListener('timeupdate', this.onTimeUpdate);
    }

    // Temporary
    // @TODO Remove
    this.onEnter();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);

    const video = this.videoRef.current;

    if (video) {
      video.removeEventListener('timeupdate', this.onTimeUpdate);
    }
  }

  onResize = () => {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;
    const video = this.videoRef.current;

    this.setState({ isMobile });

    if (!isMobile && !this.alreadyInit && video) {
      video.addEventListener('timeupdate', this.onTimeUpdate);
      this.alreadyInit = true;
      video.play();
    } else {
      this.alreadyInit = false;
    }
  }

  onEnter = () => {
    const header = this.headerRef.current;
    const group = this.groupRef.current;
    const video = this.videoRef.current;
    const timeline = new TimelineLite();

    if (video) {
      video.play();
    }

    if (!header || !group) {
      return;
    }

    timeline.staggerFromTo(
      [header, group],
      0.65,
      {
        opacity: 0,
        y: 60,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power4.easeInOut,
      },
      0.125,
    );
  }

  onTimeUpdate = () => {
    const video = this.videoRef.current;

    if (!video) {
      return;
    }

    this.setState({ currentTime: video.currentTime });
  }

  onClick = (start) => {
    const { isMobile } = this.state;

    if (isMobile) {
      return;
    }

    const video = this.videoRef.current;

    if (!video) {
      return;
    }

    video.currentTime = start;
    video.play();
  }

  render() {
    const {
      children,
      heading,
      description,
      ctaText,
      ctaUrl,
      video,
      poster,
      headingCentered,
      videoPosition,
    } = this.props;
    const { currentTime, isMobile } = this.state;
    const hasButton = ctaText && ctaUrl;

    return (
      <ViewportEnter threshold={0.4} onEnter={this.onEnter}>
        <div className={s('video', `position_${videoPosition}`)}>
          <div
            className={s.video__header}
            ref={this.headerRef}
          >
            <Leader
              heading={heading}
              text={description}
              centered={headingCentered}
            >
              {hasButton && (
                <Button
                  to={ctaUrl}
                  color="transparent"
                >
                  {ctaText}
                </Button>
              )}
            </Leader>
          </div>

          <div className={s.video__container}>
            <div
              className={s.video__group}
              ref={this.groupRef}
            >
              <div className={s.video__row}>
                <div className={s.video__interaction}>
                  <div className={s.video__steps}>
                    {Children.map(children, (c, index) => cloneElement(c, {
                      index,
                      onClick: this.onClick,
                      isActive: currentTime > c.props.start && currentTime <= c.props.end,
                    }))}
                  </div>

                  {!isMobile && (
                    <div className={s.video__wrapper}>
                      <video
                        ref={this.videoRef}
                        src={video}
                        className={s.video__src}
                        preload="true"
                        playsInline
                        muted
                        autoPlay
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ViewportEnter>
    );
  }
}
