import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { graphql } from 'gatsby';
import WideImage from 'components/wide-image';

export default class WideImageModule extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
        contentType: PropTypes.string,
        details: PropTypes.shape({
          image: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
          }),
        }),
      }),
      description: PropTypes.string,
    }),
    imagePosition: PropTypes.oneOf(['center', 'right']),
    block01Heading: PropTypes.string,
    block01Text: PropTypes.shape({
      text: PropTypes.string,
    }),
    block02Heading: PropTypes.string,
    block02Text: PropTypes.shape({
      text: PropTypes.string,
    }),
    block03Heading: PropTypes.string,
    block03Text: PropTypes.shape({
      text: PropTypes.string,
    }),
    block04Heading: PropTypes.string,
    block04Text: PropTypes.shape({
      text: PropTypes.string,
    }),
  }

  buildBlockList = () => {
    const blockList = [];

    const {
      block01Heading,
      block01Text,
      block02Heading,
      block02Text,
      block03Heading,
      block03Text,
      block04Heading,
      block04Text,
    } = this.props;

    if (block01Heading) {
      blockList.push({
        heading: block01Heading,
        text: _get(block01Text, 'text'),
      });
    }
    if (block02Heading) {
      blockList.push({
        heading: block02Heading,
        text: _get(block02Text, 'text'),
      });
    }
    if (block03Heading) {
      blockList.push({
        heading: block03Heading,
        text: _get(block03Text, 'text'),
      });
    }
    if (block04Heading) {
      blockList.push({
        heading: block04Heading,
        text: _get(block04Text, 'text'),
      });
    }
    return blockList;
  }

  render() {
    const {
      heading,
      text,
      image,
      imagePosition,
      ctaText,
      ctaUrl,
    } = this.props;

    return (
      <WideImage
        heading={heading}
        text={_get(text, 'text')}
        image={{
          src: _get(image, 'file.url'),
          alt: _get(image, 'description'),
          width: _get(image, 'file.details.image.width'),
          height: _get(image, 'file.details.image.height'),
        }}
        imagePosition={imagePosition}
        blocks={this.buildBlockList()}
        cta={{
          text: ctaText,
          url: ctaUrl,
        }}
      />
    );
  }
}

export const query = graphql`
  fragment wideImageModule on ContentfulModuleWideImage {
    heading: title
    text: body {
      text: body
    }
    ctaText
    ctaUrl
    image {
      ...image
    }
    imagePosition
    block01Heading: block01Title
    block01Text: block01Body {
      text: block01Body
    }
    block02Heading: block02Title
    block02Text: block02Body {
      text: block02Body
    }
    block03Heading: block03Title
    block03Text: block03Body {
      text: block03Body
    }
    block04Heading: block04Title
    block04Text: block04Body {
      text: block04Body
    }
  }
`;
