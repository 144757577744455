import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';
import Icon from 'components/icon';

import s from './IconBlock.scss';

export default class IconBlock extends PureComponent {

  static propTypes = {
    icon: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    heading: PropTypes.string.isRequired,
    text: PropTypes.string,
    cta: PropTypes.node,
    className: PropTypes.string,
    wide: PropTypes.bool,
  }

  render() {
    const { icon, image, heading, text, cta, wide, className } = this.props;

    return (
      <div className={s('iconBlock', className, { wide, [s.hasCta]: cta })}>
        {icon && (
          <div className={s.iconBlock__icon}>
            <Icon id={icon} />
          </div>
        )}
        {(image && image.src) &&
          <div className={s.iconBlock__image}>
            <Image
              src={image.src}
              alt={image.alt || ''}
              width={image.width}
              height={image.height}
            />
          </div>
        }
        <h2 className={s.iconBlock__heading}>
          {heading}
        </h2>
        {text &&
          <p className={s.iconBlock__text}>
            {text.split('\n').map((line, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`text-${index}`}>
                {index !== 0 && (<br />)}
                {line}
              </Fragment>
            ))}
          </p>
        }
        {cta &&
          <div className={s.iconBlock__cta}>
            {cta}
          </div>
        }
      </div>
    );
  }
}
