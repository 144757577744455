/* this will probably need to be located & called somethign else */
import React from 'react';
import PropTypes from 'prop-types';

import Clickable from 'components/clickable';
import { Container } from 'components/layout';
import Image from 'components/image';

import s from './CardSetFooter.scss';

const CardSetFooter = ({ text, images, cta }) => (
  <div className={s('cardSetFooter', { [s.hasImages]: images })}>
    <Container>
      <div className={s.cardSetFooter__inner}>
        { images && (
          <div className={s.cardSetFooter__row}>
            {images.map(image => (
              <div className={s.cardSetFooter__imageWrap} key={image.src}>
                <Image
                  src={image.src}
                  alt={image.alt || ''}
                  width={image.width}
                  height={image.height}
                  className={s.cardSetFooter__image}
                  transition="none"
                />
              </div>
            ))}
          </div>
        )}

        <p className={s.cardSetFooter__text}>
          {text}
          {cta.url &&
            <span className={s.cardSetFooter__cta}>
              <Clickable to={cta.url}>{cta.text}</Clickable>
            </span>
          }
        </p>
      </div>
    </Container>
  </div>
);

CardSetFooter.propTypes = {
  images: PropTypes.array,
  text: PropTypes.string,
  cta: PropTypes.object,
};

export default CardSetFooter;
