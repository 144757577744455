import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';

import s from './Cite.scss';

const Cite = ({ name, role, headshot }) => (
  <div className={s.cite}>
    {headshot &&
      <div className={s.cite__headshot}>
        <Image
          src={headshot}
          alt={name || ''}
          transition="none"
        />
      </div>
    }
    <span>
      {name && <span className={s.cite__name}>{name}</span>}
      {role && <span className={s.cite__role}>{role}</span>}
    </span>
  </div>
);

Cite.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  headshot: PropTypes.string,
};

export default Cite;
