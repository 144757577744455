import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';

import s from './ImageGrid.scss';

export default class ImageGrid extends PureComponent {

  static propTypes = {
    items: PropTypes.array, // should we validate to 4 images exactly?
  }

  static defaultProps = {
    items: [],
  }

  render() {
    return (
      <div className={s.imageGrid}>
        {this.props.items.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${s.imageGrid}-${i}`} className={s.imageGrid__item}>
            <div className={s.imageGrid__image}>
              <Image
                src={item.src}
                width={item.width}
                height={item.height}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}
