import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import Submodules from 'containers/submodules';

import { Container } from 'components/layout';
import Leader from 'components/leader';
import { PriceCard } from '.';
import s from './PriceWithFeatures.scss';

export default class IconSet extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    features: PropTypes.array,
    priceHeading: PropTypes.string,
    price: PropTypes.string,
    priceText: PropTypes.string,
    priceCta: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }

  static defaultProps = {
    heading: null,
    text: null,
    features: [],
    priceHeading: null,
    price: null,
    priceText: null,
    priceCta: {},
  }

  render() {

    const {
      heading,
      text,
      features,
      priceHeading,
      price,
      priceText,
      priceCta,
    } = this.props;

    return (
      <div className={s.priceWithFeatures}>
        <Leader className={s.priceWithFeatures__intro} centered heading={heading} text={text} />
        <hr className={s.priceWithFeatures__hr} />
        <Container>

          <div className={s.priceWithFeatures__grid}>

            <div className={s('priceWithFeatures__priceCard', 'priceWithFeatures__feature')}>
              <PriceCard price={price} heading={priceHeading} text={priceText} cta={priceCta} />
            </div>

            {features && features.map(submodule => (
              <div
                key={_get(submodule, 'heading')}
                className={s.priceWithFeatures__feature}
              >
                <div className={s.priceWithFeatures__featureInner}>
                  <Submodules list={[submodule]} />
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
    );
  }
}
