import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';

import s from './Card.scss';

export default class Card extends PureComponent {

  static propTypes = {
    image: PropTypes.string,
    index: PropTypes.number,
    heading: PropTypes.string,
    text: PropTypes.string,
  }

  render() {
    const { image, index, heading, text } = this.props;

    return (
      <div className={s.card}>
        <div className={s.card__imageWrap}>
          <div className={s.card__image}>
            <Image src={image} />
          </div>
        </div>
        <div className={s(s.card__content, { numbered: index })}>
          <h2 className={s.card__heading}>
            {index && <span className={s.card__index}>0{index}</span>}
            {heading}
          </h2>
          <p className={s.card__text}>{text}</p>
        </div>
      </div>
    );
  }
}
