import React from 'react';
import PropTypes from 'prop-types';

import s from './CarouselButton.scss';

const CarouselButton = ({ direction, disabled, onClick }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={s('carouselButton', direction, { disabled })}
  >
    <span className={s.carouselButton__inner}>
      <svg viewBox="0 0 18 34" className={s.carouselButton__svg}>
        <title>{direction}</title>
        <path fill="currentColor" d="M.3 32c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l15.7-15.8c.5-.5.5-1.2 0-1.7L2 .3C1.5-.2.8-.2.3.3S-.2 1.5.3 2l14.8 15L.3 32z" />
      </svg>
    </span>
  </button>
);

CarouselButton.propTypes = {
  direction: PropTypes.oneOf(['previous', 'next']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

CarouselButton.defaultProps = {
  direction: 'next',
  onClick: () => null,
};

export default CarouselButton;

