import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import _get from 'lodash/get';

import kebabCaseIcon from 'utils/kebab-case-icon';

import IconBlock from 'components/icon-block';
import Button from 'components/button';
import Clickable from 'components/clickable';

export default class IconBlockContainer extends Component {

  static propTypes = {
    icon: PropTypes.string.isRequired,
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
        contentType: PropTypes.string,
        details: PropTypes.shape({
          image: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
          }),
        }),
      }),
      description: PropTypes.string,
    }),
    heading: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    ctaIsButton: PropTypes.bool,
  }

  static defaultProps = {
    image: null,
    heading: '',
    text: null,
    ctaText: 'Learn more',
    ctaUrl: null,
    ctaIsButton: true,
  }

  render() {
    const {
      icon,
      image,
      heading,
      text,
      ctaText,
      ctaUrl,
      ctaIsButton,
    } = this.props;

    const isButton = ctaIsButton === true || ctaIsButton === null;

    return (
      <IconBlock
        icon={kebabCaseIcon(icon)}
        image={{
          src: _get(image, 'file.url'),
          alt: _get(image, 'description'),
          width: _get(image, 'file.details.image.width'),
          height: _get(image, 'file.details.image.height'),
        }}
        heading={heading}
        text={_get(text, 'text')}
        cta={ctaUrl && (
          isButton ?
            <Button to={ctaUrl} color="transparent">{ctaText}</Button>
          :
            <Clickable to={ctaUrl}>{ctaText}</Clickable>
        )}
      />
    );
  }
}

export const query = graphql`
  fragment iconBlock on ContentfulIconBlock {
    icon: svgIcon
    image {
      ...image
    }
    heading: title
    text: body {
      text: body
    }
    ctaText
    ctaUrl
    ctaIsButton
  }
`;
