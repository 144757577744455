import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';

import s from './ImageComparison.scss';
import { ImageComparisonContent, ImageComparisonControls } from '.';

export default class ImageComparison extends Component {

  constructor(props) {
    super(props);
    this.state = {
      progress: 0.5,
      variant: 0,
    };

    this.wrapperRef = React.createRef();
    this.progressRef = React.createRef();
  }

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        left: PropTypes.shape({
          src: PropTypes.string,
          color: PropTypes.string,
          price: PropTypes.string,
        }),
        right: PropTypes.shape({
          src: PropTypes.string,
          color: PropTypes.string,
          price: PropTypes.string,
        }),
      }),
    ),
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    className: PropTypes.string,
    optionHeading: PropTypes.string,
  }

  componentDidMount = () => {
    const controlElement = this.progressRef.current;

    // for mobile
    controlElement.addEventListener('touchstart', this.startSliding);
    window.addEventListener('touchend', this.finishSliding);

    controlElement.addEventListener('mousedown', this.startSliding);
    window.addEventListener('mouseup', this.finishSliding);
  };

  startSliding = (e) => {
    // Prevent default behavior other than mobile scrolling
    if (!('touches' in e)) {
      e.preventDefault();
    }

    // Slide the image even if you just click or tap (not drag)
    this.handleSliding(e);

    window.addEventListener('mousemove', this.handleSliding);
    window.addEventListener('touchmove', this.handleSliding);
  };

  finishSliding = () => {
    window.removeEventListener('mousemove', this.handleSliding);
    window.removeEventListener('touchmove', this.handleSliding);
  };

  onChangeImage = (i) => {
    if (i !== this.state.variant) {
      this.setState({
        variant: i,
      });
    }
  }

  handleSliding = (event) => {
    const e = event || window.event;
    const { width } = this.wrapperRef.current.getBoundingClientRect();

    // Calc Cursor Position from the left edge of the viewport
    const cursorXfromViewport = e.touches ? e.touches[0].pageX : e.pageX;

    // Calc Cursor Position from the left edge of the window (consider any page scrolling)
    const cursorXfromWindow = cursorXfromViewport - window.pageXOffset;

    // Calc Cursor Position from the left edge of the image
    let pos = cursorXfromWindow;

    // Set minimum and maximum values to prevent the slider from overflowing
    const minPos = 0;
    const maxPos = width;

    if (pos < minPos) pos = minPos;
    if (pos > maxPos) pos = maxPos;

    this.setState({
      progress: pos / maxPos,
    });
  };

  controlButtons = () => {
    const { optionHeading, images } = this.props;

    if (images.length > 1) {
      return (
        <ImageComparisonControls
          label={optionHeading}
          active={this.state.variant}
          onChangeImage={this.onChangeImage}
        />
      );
    }

    return null;
  }

  render() {
    const {
      images,
      className,
      width,
      height,
      heading,
      text,
    } = this.props;

    const { progress, variant } = this.state;
    const clipPath = `polygon(0 0, ${100 * progress}% 0, ${100 * progress}% 100%, 0% 100%)`;

    const leftImage = images[variant].left;
    const rightImage = images[variant].right;

    return (
      <Fragment>
        <div className={s('imageComparison', className)}>

          <div
            className={s.imageComparison__content}
            style={{
              clipPath,
              WebkitClipPath: clipPath,
            }}
          >
            <ImageComparisonContent
              heading={heading}
              text={text}
              price={leftImage.price}
              color={leftImage.color}
            >
              {this.controlButtons()}
            </ImageComparisonContent>
          </div>

          <div className={s.imageComparison__wrapper} ref={this.wrapperRef}>
            <div
              className={s.imageComparison__ratio}
              style={{ paddingBottom: `${(height / width) * 100}%` }}
            />
            <div className={s.imageComparison__inner}>
              <div
                className={s.imageComparison__leftWrapper}
                style={{ width: `${100 * progress}%` }}
              >
                <div className={s('imageComparison__image', 'imageComparison__left')}>
                  <Image
                    src={leftImage.src}
                    transition="none"
                  />
                </div>
              </div>

              <div className={s('imageComparison__image', 'imageComparison__right')}>
                <Image
                  src={rightImage.src}
                  transition="none"
                />
              </div>

              <div className={s('imageComparison__content', 'imageComparison__contentRight')}>
                <ImageComparisonContent
                  heading={heading}
                  text={text}
                  price={rightImage.price}
                  color={rightImage.color}
                >
                  {this.controlButtons()}
                </ImageComparisonContent>
              </div>

              <div className={s.imageComparison__measure} ref={this.progressRef} />

              <div className={s.imageComparison__handleWrapper} style={{ left: `${100 * progress}%` }}>
                <svg viewBox="0 0 80 80" className={s.imageComparison__handle}>
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <circle fill="currentColor" fillRule="nonzero" cx="40" cy="40" r="40" />
                    <g>
                      <path d="M17.1700278,40.9999477 L25.1230055,49.1482128 C25.5771554,49.6135573 24.8955736,50.3119211 24.4414237,49.8465953 L16.1480854,41.3488295 C16.0651245,41.2645553 16.0200665,41.1722199 16.004332,41.0806162 C15.9857375,40.918664 16.025788,40.7764961 16.1480854,40.6511786 L24.4414237,32.1534123 C24.8955736,31.6880679 25.5771371,32.3864504 25.1230055,32.8517949 L17.1700278,40.9999477 Z" id="Fill-1" stroke="#FFFFFF" strokeWidth="1" fill="#FFFFFF" />
                      <path d="M55.8973005,40.9999477 L63.8502782,49.1482128 C64.3044281,49.6135573 63.6228463,50.3119211 63.1686964,49.8465953 L54.8753581,41.3488295 C54.7923972,41.2645553 54.7473393,41.1722199 54.7316047,41.0806162 C54.7130103,40.918664 54.7530608,40.7764961 54.8753581,40.6511786 L63.1686964,32.1534123 C63.6228463,31.6880679 64.3044098,32.3864504 63.8502782,32.8517949 L55.8973005,40.9999477 Z" id="Fill-1" stroke="#FFFFFF" strokeWidth="1" fill="#FFFFFF" transform="translate(59.363636, 41.000000) rotate(-180.000000) translate(-59.363636, -41.000000) " />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
