import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';

import s from './ImageSetItem.scss';

export default class ImageSetItem extends PureComponent {

  static propTypes = {
    image: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    caption: PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string,
      centered: PropTypes.bool,
    }),
    orientation: PropTypes.oneOf([
      'portrait',
      'landscape',
      'square',
      'unconstrained',
    ]),
  }

  render() {
    const { image, caption, orientation, width, height } = this.props;
    const IMAGE_WIDTH = 660;

    return (
      <figure className={s.imageSetItem}>
        <div className={s(s.imageSetItem__imageWrap, orientation)}>
          <div className={s.imageSetItem__image}>
            <Image
              src={`${image}?w=${IMAGE_WIDTH}`}
              src2x={`${image}?w=${IMAGE_WIDTH * 2}`}
              transition="scale"
              width={width}
              height={height}
            />
          </div>
        </div>
        <figcaption className={s(s.imageSetItem__caption, { centered: caption.centered })}>
          {caption.heading &&
            <h3 className={s.imageSetItem__heading}>{caption.heading}</h3>
          }
          {caption.text &&
            <p className={s.imageSetItem__text}>{caption.text}</p>
          }
        </figcaption>

      </figure>
    );
  }
}
