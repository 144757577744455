import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';

import Tooltip from 'components/tooltip';

import s from './AccordionItem.scss';

export default class AccordionItem extends PureComponent {

  static propTypes = {
    tooltip: PropTypes.string,
    heading: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
    index: PropTypes.number,
    active: PropTypes.bool,
  };

  render() {
    const { heading, text, tooltip, onClick, index, active } = this.props;

    return (
      <div key={heading} className={s('accordionItem', { active })}>
        <div className={s.accordionItem__headingWrap}>
          <h3 className={s.accordionItem__heading}>
            <button
              className={s.accordionItem__button}
              onClick={() => { onClick(active ? -1 : index); }}
            >
              {heading}
            </button>
            {tooltip && (
              <div className={s.accordionItem__tooltip}>
                <Tooltip>{tooltip}</Tooltip>
              </div>
              )}
          </h3>

          <button
            className={s.accordionItem__chevron}
            onClick={() => { onClick(active ? -1 : index); }}
          >
            <Icon id="chevron-rounded-large" />
          </button>
        </div>

        <div className={s.accordionItem__content}>
          {text && active && <p className={s.accordionItem__text}>{text}</p>}
        </div>
      </div>
    );
  }
}
