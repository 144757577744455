import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import _get from 'lodash/get';
import ProductCta from 'components/product-cta';
import Cta, { AppCta } from 'components/cta';

export default class ProductCtaModule extends Component {

  static propTypes = {
    ctaType: PropTypes.oneOf(['CTA only', 'Product CTA', 'App CTA']).isRequired,
    heading: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    richText: PropTypes.shape({
      text: PropTypes.string,
    }),
    ctaIconId: PropTypes.string,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    ctaColor: PropTypes.oneOf(['red', 'white', 'transparent']),
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
        contentType: PropTypes.string,
        details: PropTypes.shape({
          image: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
          }),
        }),
      }),
      description: PropTypes.string,
    }),
    backgroundImage: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
        contentType: PropTypes.string,
        details: PropTypes.shape({
          image: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
          }),
        }),
      }),
      description: PropTypes.string,
    }),
    backgroundCirclesAnimation: PropTypes.oneOf(['Enable', 'Disable']),
    containBackground: PropTypes.bool,
    padding: PropTypes.oneOf(['small', 'medium', 'large']),
    contactHeading: PropTypes.string,
    contactText: PropTypes.string,
    contactCtaText: PropTypes.string,
    contactCtaUrl: PropTypes.string,
  }

  static defaultProps = {
    heading: '',
    text: null,
    ctaIconId: null,
    ctaText: 'Learn more',
    ctaUrl: null,
    ctaColor: 'transparent',
    image: null,
    backgroundImage: null,
    containBackground: false,
    padding: 'medium',
    contactHeading: null,
    contactText: null,
    contactCtaText: null,
    contactCtaUrl: null,
  }

  render() {
    const {
      ctaType,
      heading,
      text,
      richText,
      ctaIconId,
      ctaText,
      ctaUrl,
      ctaColor,
      image,
      backgroundImage,
      backgroundCirclesAnimation,
      containBackground,
      padding,
      contactHeading,
      contactText,
      contactCtaText,
      contactCtaUrl,
    } = this.props;

    return (
      <Fragment>
        {ctaType === 'Product CTA' && (
          <ProductCta
            heading={heading}
            text={_get(text, 'text')}
            cta={{
              icon: ctaIconId,
              text: ctaText,
              url: ctaUrl,
              color: ctaColor || 'red',
            }}
            backgroundImage={{
              src: _get(backgroundImage, 'file.url'),
              alt: _get(backgroundImage, 'description'),
              width: _get(backgroundImage, 'file.details.image.width'),
              height: _get(backgroundImage, 'file.details.image.height'),
            }}
            image={{
              src: _get(image, 'file.url'),
              alt: _get(image, 'description'),
              width: _get(image, 'file.details.image.width'),
              height: _get(image, 'file.details.image.height'),
            }}
            contactHeading={contactHeading}
            contactText={contactText}
            contactCta={{
              text: contactCtaText || 'Contact Us',
              url: contactCtaUrl,
            }}
          />
        )}
        {ctaType === 'CTA only' && (
          <Cta
            heading={heading}
            text={_get(text, 'text')}
            cta={{
              icon: ctaIconId,
              text: ctaText,
              url: ctaUrl,
              color: ctaColor || 'red',
            }}
            backgroundImage={{
              src: _get(backgroundImage, 'file.url'),
              alt: _get(backgroundImage, 'description'),
              width: _get(backgroundImage, 'file.details.image.width'),
              height: _get(backgroundImage, 'file.details.image.height'),
            }}
            circles={backgroundCirclesAnimation}
            containBackground={containBackground}
            padding={padding}
          />
        )}
        {ctaType === 'App CTA' && (
          <AppCta
            heading={heading}
            text={_get(text, 'text')}
            richText={_get(richText, 'text')}
            cta={{
              icon: ctaIconId,
              text: ctaText,
              url: ctaUrl,
              color: ctaColor || 'red',
            }}
            backgroundImage={{
              src: _get(backgroundImage, 'file.url'),
              alt: _get(backgroundImage, 'description'),
              width: _get(backgroundImage, 'file.details.image.width'),
              height: _get(backgroundImage, 'file.details.image.height'),
            }}
            containBackground={containBackground}
            padding={padding}
          />
        )}
      </Fragment>
    );
  }
}

export const query = graphql`
  fragment productCtaModule on ContentfulModuleProductCta {
    ctaType
    heading: title
    text: body {
      text: body
    }
    richText {
      text: richText
    }
    ctaIconId
    ctaText
    ctaUrl
    ctaColor
    image {
      ...image
    }
    backgroundImage {
      ...image
    }
    backgroundCirclesAnimation
    containBackground
    padding
    contactHeading: contactTitle
    contactText: contactBody
    contactCtaText
    contactCtaUrl
  }
`;
