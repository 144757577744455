/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

// Add new modules as imports here
import AnimatedListModule from './AnimatedListModule';
import CardSetModule from './CardSetModule';
import IconSetModule from './IconSetModule';
import ImageModule from './ImageModule';
import ImageGridModule from './ImageGridModule';
import ImageSetModule from './ImageSetModule';
import ImageSwapModule from './ImageSwapModule';
import ImageVariantModule from './ImageVariantModule';
import WideImageModule from './WideImageModule';
import LogosModule from './LogosModule';
import PriceWithFeaturesModule from './PriceWithFeaturesModule';
import ProductComparisonModule from './ProductComparisonModule';
import ProductCtaModule from './ProductCtaModule';
import TestimonialModule from './TestimonialModule';

import s from '../../styles/spacing.scss';

/**
 * List of Contentful modules that can be rendered as components. Each key comes from the GraphQL
 * schema, and the value is the corresponding component.
 * @type Object.<String, Function>
 */
const MODULES = {
  ContentfulModuleAnimatedList: AnimatedListModule,
  ContentfulModuleCardSet: CardSetModule,
  ContentfulModuleIconSet: IconSetModule,
  ContentfulModuleImage: ImageModule,
  ContentfulModuleImageGrid: ImageGridModule,
  ContentfulModuleImageSet: ImageSetModule,
  ContentfulModuleImageSwappable: ImageSwapModule,
  ContentfulModuleImageVariant: ImageVariantModule,
  ContentfulModuleWideImage: WideImageModule,
  ContentfulModuleLogos: LogosModule,
  ContentfulModulePriceWithFeatures: PriceWithFeaturesModule,
  ContentfulModuleProductComparison: ProductComparisonModule,
  ContentfulModuleProductCta: ProductCtaModule,
  ContentfulModuleTestimonial: TestimonialModule,
};

/**
 * Component to render a series of Contentful modules as individual components.
 */
export default class Modules extends Component {

  static propTypes = {
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    children: PropTypes.node,
  }

  render() {
    const { list, children } = this.props;

    return (
      <div className={s.modules}>
        {list.map((module, i) => {
          const moduleName = module.__typename;
          const Module = MODULES[moduleName] || (() => null);

          const unstyledModules = [
            'ContentfulModuleProductCta',
            'ContentfulModuleImageGrid',
            'ContentfulModuleIconSet',
            'ContentfulModuleProductComparison',
          ];

          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${moduleName}-${i}`}
              className={s('modules__item', moduleName,
              { [s.noSpacing]: unstyledModules.indexOf(moduleName) > -1 })}
            >
              <Module {...module}>
                {children}
              </Module>
            </div>
          );
        })}
      </div>
    );
  }
}

export const query = graphql`
  fragment moduleList on Node {
    __typename
    ...animatedListModule
    ...cardSetModule
    ...iconSetModule
    ...imageModule
    ...imageGridModule
    ...imageSetModule
    ...imageSwapModule
    ...imageVariantModule
    ...wideImageModule
    ...logosModule
    ...priceWithFeaturesModule
    ...productComparisonModule
    ...productCtaModule
    ...testimonialModule
  }
`;
