import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';

import s from './IconTable.scss';

export default class IconTable extends Component {

  static propTypes = {
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
        icon: PropTypes.string,
      }),
    ),
    onUpdate: PropTypes.func,
  }

  render() {
    const { rows, onUpdate } = this.props;

    return (
      <ul className={s.iconTable} onMouseLeave={() => { onUpdate(0); }}>
        {rows.map((row, i) => (
          <li
            key={row.heading}
            className={s.iconTable__row}
            onMouseEnter={() => { onUpdate(i + 1); }}
          >
            <div className={s.iconTable__iconWrap}>
              {row.icon &&
                <Icon id={row.icon} className={s.iconTable__icon} />
              }
            </div>
            <div className={s.iconTable__content}>
              <h3 className={s.iconTable__heading}>{row.heading}</h3>
              <p className={s.iconTable__text}>{row.text}</p>
            </div>
          </li>
        ))}
      </ul>
    );
  }
}
