import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import _get from 'lodash/get';

import Testimonial from 'components/testimonial';

export default class TestimonialModule extends Component {

  static propTypes = {
    companyLogo: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
      description: PropTypes.string,
    }),
    companyName: PropTypes.string,
    quote: PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    authorName: PropTypes.string,
    authorRole: PropTypes.string,
    authorThumbnail: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
      description: PropTypes.string,
    }),
    authorPortrait: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
      description: PropTypes.string,
    }),
  }

  static defaultProps = {
    companyName: null,
    authorName: null,
    authorRole: null,
  }

  render() {
    const {
      companyLogo,
      companyName,
      quote,
      authorName,
      authorRole,
      authorThumbnail,
      authorPortrait,
    } = this.props;

    return (
      <Testimonial
        companyLogo={_get(companyLogo, 'file.url')}
        companyName={companyName || _get(companyLogo, 'description')}
        quote={quote.text}
        authorName={authorName}
        authorRole={authorRole}
        authorThumbnail={_get(authorThumbnail, 'file.url') || _get(authorPortrait, 'file.url')}
      />
    );
  }
}

export const query = graphql`
  fragment testimonialModule on ContentfulModuleTestimonial {
    quote {
      text: quote
    }
    authorName
    authorRole: authorTitle
    authorThumbnail: authorPhoto {
      ...image
    }
    authorPortrait {
      ...image
    }
    companyLogo {
      ...image
    }
    companyName
  }
`;
