import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import s from './AccordionList.scss';
import { AccordionItem } from '.';

export default class AccordionList extends PureComponent {

  state = {
    activeItem: -1,
  }

  static propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        tooltip: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }

  static defaultProps = {
    list: [],
  }

  onUpdate = (i) => {
    this.setState({
      activeItem: i,
    });
  }

  render() {
    return (
      <div className={s.accordionList}>
        {this.props.list.map((item, i) => (
          <AccordionItem
            heading={item.heading}
            text={item.text}
            tooltip={item.tooltip}
            index={i}
            active={i === this.state.activeItem}
            onClick={this.onUpdate}
            key={item.heading}
          />
        ))}
      </div>
    );
  }
}
