import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Cite from 'components/cite';
import { Container } from 'components/layout';
import Image from 'components/image';

import s from './Testimonial.scss';

export default class Testimonial extends Component {

  static propTypes = {
    companyLogo: PropTypes.string,
    companyName: PropTypes.string,
    quote: PropTypes.string.isRequired,
    authorName: PropTypes.string,
    authorRole: PropTypes.string,
    authorThumbnail: PropTypes.string,
  }

  static defaultProps = {
    companyLogo: null,
    companyName: null,
    authorName: null,
    authorRole: null,
    authorThumbnail: null,
  }

  render() {
    const {
      companyLogo,
      companyName,
      quote,
      authorName,
      authorRole,
      authorThumbnail,
    } = this.props;

    return (
      <Container>
        <div className={s.testimonial}>
          {companyLogo &&
            <div className={s.testimonial__logo}>
              <Image
                src={companyLogo}
                alt={companyName || ''}
                transition="none"
              />
            </div>
          }
          <h2 className={s.testimonial__quote}>
            &ldquo;{quote}&rdquo;
          </h2>

          <Cite headshot={authorThumbnail} name={authorName} role={authorRole} />
        </div>
      </Container>
    );
  }
}
