// heading plus text (plus cta) component

import React, { Children, Component } from 'react'
import PropTypes from 'prop-types'

import { Container, Row } from 'components/layout'

import s from './Leader.scss'

export default class Leader extends Component {
  static propTypes = {
    heading: PropTypes.string,
    headingTag: PropTypes.string,
    customHeading: PropTypes.node,
    text: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    centered: PropTypes.bool,
  }

  static defaultProps = {
    children: undefined,
    headingTag: 'h2',
  }

  render() {
    const {
      heading,
      headingTag,
      customHeading,
      text,
      children,
      className,
      centered,
    } = this.props
    const HeadingTag = headingTag

    return (
      <div className={s('leader', className, { centered })}>
        <Container>
          <Row>
            <div className={s('leader__headingWrap', { [s.hasText]: text })}>
              {heading && (
                <HeadingTag className={s('leader__heading', headingTag)}>
                  {heading}
                </HeadingTag>
              )}
              {!heading && customHeading}
            </div>

            <div
              className={s('leader__textCol', { visible: text || children })}
            >
              <div className={s.leader__textWrap}>
                {text && <div className={s.leader__text}>{text}</div>}

                {children &&
                  Children.map(children, (child, i) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${s.leader__children}-${i}`}
                      className={s.leader__children}
                    >
                      {child}
                    </div>
                  ))}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    )
  }
}
