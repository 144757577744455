import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components/layout';

import s from './ImageComparisonContent.scss';

const ImageComparisonContent = ({ heading, text, price, color, children }) => (
  <div className={s('imageComparisonContent', color)}>
    <Container>
      <div className={s.imageComparisonContent__inner}>
        <div className={s.imageComparisonContent__content}>
          <h1 className={s.imageComparisonContent__heading}>{heading}</h1>
          <p className={s.imageComparisonContent__text}>{text}
            <span className={s.imageComparisonContent__price}>{price}</span>
          </p>
        </div>
        {children}
      </div>
    </Container>
  </div>
);

ImageComparisonContent.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  price: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.element,
};

ImageComparisonContent.defaultProps = {
  color: 'light',
};

export default ImageComparisonContent;

