import React, { PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';

import s from './ImageTransition.scss';

export default class ImageTransition extends PureComponent {

  static propTypes = {
    color: PropTypes.string, // hex
    className: PropTypes.string,
    children: PropTypes.element.isRequired,
    type: PropTypes.oneOf(['scale', 'wipe', 'fade']),
    active: PropTypes.bool,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }

  render() {

    const { type, color, className, width, height, children, active } = this.props;

    const style = color ? { backgroundColor: color } : null;

    return (
      <div className={s('imageTransition', className, type, { active })} style={style}>
        <div className={s.imageTransition__ratio} style={{ paddingBottom: `${(height / width) * 100}%` }} />
        {type === 'wipe' && (<div className={s.imageTransition__wipe} />)}
        <div className={s.imageTransition__inner}>
          {cloneElement(children, {
            className: s.imageTransition__image,
          })}
        </div>
      </div>
    );
  }
}
