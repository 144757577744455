import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';

import s from './CarouselItem.scss';

export default class CarouselItem extends PureComponent {

  static propTypes = {
    active: PropTypes.bool,
    image: PropTypes.string,
    index: PropTypes.number,
    heading: PropTypes.string,
    text: PropTypes.string,
  }

  render() {
    const { active, image, index, heading, text } = this.props;

    return (
      <div className={s('carouselItem', { active })}>
        <div className={s.carouselItem__imageWrap}>
          <div className={s.carouselItem__image}>
            <Image transition="none" src={image} />
          </div>
        </div>
        <div className={s.carouselItem__content}>
          <h2 className={s.carouselItem__heading}>
            <span className={s.carouselItem__index}>0{index}</span>
            {heading}
          </h2>
          <p className={s.carouselItem__text}>{text}</p>
        </div>
      </div>
    );
  }
}
