import React, { PureComponent, Children } from 'react'
import PropTypes from 'prop-types'

import Callout from 'components/callout'
import Button from 'components/button'
import Image from 'components/image'
import { Container, Row } from 'components/layout'

import s from './ImageColumn.scss'

export default class ImageColumn extends PureComponent {
  static propTypes = {
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,
    reversed: PropTypes.bool,
    alignTop: PropTypes.bool,
    heading: PropTypes.string,
    text: PropTypes.string,
    linkText: PropTypes.string,
    link: PropTypes.string,
    contentSize: PropTypes.oneOf(['default', 'even', 'wide']).isRequired,
    childColumns: PropTypes.bool,
    children: PropTypes.node,
    boxHeading: PropTypes.string,
    boxCta: PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }

  render() {
    const {
      image,
      reversed,
      alignTop,
      heading,
      text,
      linkText,
      link,
      contentSize,
      childColumns,
      children,
      boxHeading,
      boxCta,
    } = this.props

    const childArray = Children.toArray(children)
    const size = contentSize.charAt(0).toUpperCase() + contentSize.slice(1)

    const IMAGE_WIDTH = 810

    return (
      <div
        className={s('imageColumn', `content${size}`, {
          [s.reversed]: reversed,
          [s.vCenter]: !alignTop,
          [s.hasCta]: linkText,
        })}
      >
        <Container>
          <div className={s.imageColumn__row}>
            <div className={s.imageColumn__imageCol}>
              <div className={s.imageColumn__imageWrap}>
                <div className={s.imageColumn__wipe}>
                  <Image
                    transition="wipe"
                    src={`${image.src}?w=${IMAGE_WIDTH}`}
                    src2x={`${image.src}?w=${IMAGE_WIDTH * 2}`}
                    alt={image.alt}
                    width={image.width}
                    height={image.height}
                  />
                </div>
              </div>
            </div>

            <div className={s.imageColumn__content}>
              <div className={s.imageColumn__contentInner}>
                <h1 className={s.imageColumn__heading}>{heading}</h1>
                {text && <p className={s.imageColumn__text}>{text}</p>}
                {childArray.length > 0 && (
                  <div className={s.imageColumn__children}>
                    <Row>
                      {childArray.map((child, i) => (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={`imageColumn__child-${i}`}
                          className={s(s.imageColumn__child, {
                            childColumns,
                          })}
                        >
                          <div className={s.imageColumn__childInner}>
                            {child}
                          </div>
                        </div>
                      ))}
                    </Row>
                  </div>
                )}
                {linkText && (
                  <Button to={link} color="transparent">
                    {linkText}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {boxHeading && (
            <div className={s.imageColumn__box}>
              <Callout cta={boxCta} heading={boxHeading} />
            </div>
          )}
        </Container>
      </div>
    )
  }
}
