import React, { cloneElement, Children, Component } from 'react';
import PropTypes from 'prop-types';
import { TimelineLite } from 'gsap';

import ViewportEnter from 'components/viewport-enter';
import { CarouselButton } from './';

import s from './Carousel.scss';

export default class Carousel extends Component {

  swipe = {};
  minDistance = 30;
  windowWidth = 0;

  trackRef = React.createRef()

  state = {
    activeSlide: 0,
    canMoveForward: true,
    transitioning: false,
  }

  static propTypes = {
    children: PropTypes.node,
    narrow: PropTypes.bool,
  }

  static defaultProps = {
    children: undefined,
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.trackRef.current.addEventListener('transitionend', this.onTransitionEnd);
    this.animate();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    this.trackRef.current.removeEventListener('transitionend', this.onTransitionEnd);
  }

  animate = () => {
    // enter stagger animation
    const items = this.trackRef.current.childNodes;

    this.t = new TimelineLite({ paused: true });
    this.t.staggerFromTo(items, 1, { x: 40, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, 0.2);
  }

  onEnter = () => {
    this.t.play();
  }

  onResize = () => {
    if (this.windowWidth !== window.innerWidth) {
      this.trackRef.current.style.transform = 'none';
      this.setState({
        activeSlide: 0,
      });

      this.windowWidth = window.innerWidth;

      this.onTransitionEnd();
    }
  }

  onTouchStart = (e) => {
    const touch = e.touches[0];

    this.swipe = { x: touch.clientX };
  }

  onTouchMove = (e) => {
    if (e.changedTouches && e.changedTouches.length) {
      this.swipe.swiping = true;
    }
  }

  onTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const totalX = (touch.clientX - this.swipe.x);

    if (this.swipe.swiping && totalX > this.minDistance) {
      this.onPrevious();
    } else if (this.swipe.swiping && totalX < -(this.minDistance)) {
      this.onNext();
    }

    this.swipe = {};
  }

  getItemSize = () => {
    const { children } = this.props;
    const track = this.trackRef.current;

    if (!track) {
      return;
    }

    // assumes even-sized slides
    const trackWidth = this.trackRef.current.scrollWidth;

    return (trackWidth / Children.count(children));
  }

  onTransitionEnd = () => {
    const last = this.trackRef.current.lastChild.getBoundingClientRect();

    const canMoveForward = window.innerWidth - (last.x + last.width) <= 0;

    this.setState({
      transitioning: false,
      canMoveForward,
    });
  }

  onNext = () => {
    // @TODO detect if last element is already 100% inside carousel, and disable forwards if so
    this.setState((state, props) => {
      if (state.transitioning || state.activeSlide === (Children.count(props.children) - 1)) {
        return null;
      }

      return {
        transitioning: true,
        activeSlide: state.activeSlide + 1,
      };
    });
  }

  onPrevious = () => {
    this.setState((state) => {
      if (state.transitioning || state.activeSlide === 0) {
        return null;
      }

      return {
        transitioning: true,
        activeSlide: state.activeSlide - 1,
      };
    });
  }

  render() {
    const { children, narrow } = this.props;
    const { activeSlide, canMoveForward } = this.state;

    return (
      <ViewportEnter onEnter={this.onEnter}>
        <div className={s('carousel', { narrow })}>
          <div className={s.carousel__wrapper}>
            <div className={s.carousel__container}>
              <ol
                className={s.carousel__inner}
                style={{
                  transform: `translateX(-${activeSlide * this.getItemSize()}px)`,
                }}
                ref={this.trackRef}
                onTouchStart={this.onTouchStart}
                onTouchMove={this.onTouchMove}
                onTouchEnd={this.onTouchEnd}
              >
                {Children.map(children, (child, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li className={s.carousel__item} key={`${s.carousel__item}-${i}`}>
                    {cloneElement(child, {
                      index: i + 1,
                      active: activeSlide === i,
                    })}
                  </li>
                ))}
              </ol>
            </div>

            <div className={s.carousel__buttonWrap}>
              <div className={s('carousel__button', 'carousel__buttonPrev')}>
                <CarouselButton
                  direction="previous"
                  disabled={activeSlide === 0}
                  onClick={this.onPrevious}
                />
              </div>

              <div className={s('carousel__button', 'carousel__buttonNext')}>
                <CarouselButton
                  disabled={!canMoveForward}
                  onClick={this.onNext}
                />
              </div>
            </div>
          </div>
        </div>
      </ViewportEnter>
    );
  }
}
