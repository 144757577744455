import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, Row } from 'components/layout';
import Markdown from 'components/markdown';
import Button from 'components/button';
import Image from 'components/image';

import s from './ImageColumnVariant.scss';

export default class ImageColumnVariant extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    ctas: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
    reversed: PropTypes.bool,
    children: PropTypes.node,
  }

  static defaultProps = {
    heading: '',
    text: '',
    image: null,
    ctas: [],
    reversed: false,
  }

  render() {
    const { heading, text, image, ctas, children, reversed } = this.props;

    const hasCtas = ctas.findIndex(item => item.text !== null);

    const IMAGE_WIDTH = 600;

    return (
      <Container>
        <Row reversed={reversed}>
          <div className={s.imageColumnVariant__content}>
            <div className={s.imageColumnVariant__contentInner}>
              <h1 className={s.imageColumnVariant__heading}>{heading}</h1>
              {text && <Markdown source={text} className={s.imageColumnVariant__text} />}

              {hasCtas > -1 && (
                <div className={s.imageColumnVariant__buttons}>
                  {ctas.map(cta => (
                    cta.url && (
                      <span key={cta.url} className={s.imageColumnVariant__cta}>
                        <Button to={cta.url} color="transparent">
                          {cta.text}
                        </Button>
                      </span>
                    )
                  ))}
                </div>
              )}
            </div>
          </div>
          {image &&
            <div className={s.imageColumnVariant__imageWrap}>
              <div className={s.imageColumnVariant__image}>
                <Image
                  src={`${image.src}?w=${IMAGE_WIDTH}`}
                  src2x={`${image.src}?w=${IMAGE_WIDTH * 2}`}
                  alt={image.alt || ''}
                  width={image.width}
                  height={image.height}
                  transition="none"
                />
              </div>
            </div>
          }
        </Row>
        {children &&
          <div className={s.imageColumnVariant__children}>
            {children}
          </div>
        }
      </Container>
    );
  }
}
