import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import Markdown from 'components/markdown';
import Button from 'components/button';
import { Container } from 'components/layout';

import s from './AppCta.scss';

export default class AppCta extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    richText: PropTypes.string,
    cta: PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
      color: PropTypes.oneOf(['red', 'transparent', 'white']),
    }),
    backgroundImage: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    padding: PropTypes.oneOf(['small', 'medium', 'large']),
    containBackground: PropTypes.bool,
  }

  static defaultProps = {
    heading: '',
    text: null,
    richText: null,
    cta: {},
    backgroundImage: {},
    padding: 'medium',
    containBackground: false,
  }

  render() {
    const {
      heading,
      text,
      richText,
      cta,
      backgroundImage,
      padding,
      containBackground,
    } = this.props;

    return (
      <div className={s(s.appCta, { containBackground })}>
        <div
          className={s(s.appCta__background, padding)}
          style={{ backgroundImage: `url(${_get(backgroundImage, 'src')})` }}
        >
          <Container>
            <div className={s.appCta__content}>
              <h2 className={s.appCta__heading}>{heading}</h2>
              {text && <p className={s.appCta__text}>{text}</p>}
              {richText && <Markdown className={s.appCta__richText} source={richText} />}
              {cta.url &&
                <div className={s.appCta__ctas}>
                  <Button size="large" to={cta.url} color={cta.color} className={s.appCta__button}>
                    {cta.text}
                  </Button>
                </div>
              }
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
