import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { Container } from 'components/layout';
import Button from 'components/button';
import Image from 'components/image';
import Leader from 'components/leader';

import s from './ProductCta.scss';

export default class ProductCta extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    cta: PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
      color: PropTypes.oneOf(['red', 'transparent', 'white']),
    }),
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    backgroundImage: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    contactHeading: PropTypes.string,
    contactText: PropTypes.string,
    contactCta: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }

  static defaultProps = {
    heading: '',
    text: null,
    cta: null,
    backgroundImage: null,
    contactHeading: null,
    contactText: null,
    contactCta: null,
  }

  render() {
    const {
      heading,
      text,
      cta,
      image,
      backgroundImage,
      contactHeading,
      contactText,
      contactCta,
    } = this.props;

    const hasBackground = backgroundImage.src;
    const hasContactText = contactText;

    return (
      <div className={s('productCta', { hasBackground, hasContactText })}>
        <div className={s.productCta__primary}>
          {hasBackground && (
            <div
              className={s.productCta__background}
              style={{ backgroundImage: `url(${_get(backgroundImage, 'src')})` }}
            />
          )}
          <Leader centered className={s.productCta__intro} heading={heading} text={text}>
            {cta.url &&
              <div className={s.productCta__cta}>
                <Button to={cta.url} color={cta.color} className={s.cta__button} icon={cta.icon}>
                  {cta.text}
                </Button>
              </div>
            }
          </Leader>
          {image.src &&
            <div className={s.productCta__product}>
              <div className={s.productCta__image}>
                <Image
                  src={_get(image, 'src')}
                  alt={_get(image, 'alt') || ''}
                  width={_get(image, 'width')}
                  height={_get(image, 'height')}
                />
              </div>
            </div>
          }
        </div>

        <hr />

        <Container>
          <div className={s.productCta__contact}>
            <div className={s.productCta__contactInner}>

              {contactHeading &&
                <h3 className={s.productCta__contactHeading}>
                  {contactHeading}
                </h3>
              }
              {contactText &&
                <p className={s.productCta__contactText}>
                  {contactText}
                </p>
              }
            </div>
            {contactCta.url &&
              <Button
                className={s.productCta__contactCta}
                to={contactCta.url}
                color="transparent"
              >
                {contactCta.text}
              </Button>
            }
          </div>
        </Container>
      </div>
    );
  }
}
