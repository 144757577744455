import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _get from 'lodash/get'

import ImageSet from 'components/image-set'

export default class ImageSetModule extends Component {
  static propTypes = {
    headingLarge: PropTypes.bool,
    heading: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    imageSet: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
          description: PropTypes.string,
        })
      ),
      imageOrientation: PropTypes.oneOf([
        'portrait',
        'landscape',
        'square',
        'unconstrained',
      ]),
    }),
    captionCentered: PropTypes.bool,
    boxHeading: PropTypes.string,
    boxCtaIconId: PropTypes.string,
    boxCtaText: PropTypes.string,
    boxCtaUrl: PropTypes.string,
  }

  render() {
    const {
      headingLarge,
      heading,
      text,
      imageSet,
      captionCentered,
      boxHeading,
      boxCtaIconId,
      boxCtaText,
      boxCtaUrl,
    } = this.props

    return (
      <ImageSet
        headingLarge={headingLarge}
        heading={heading}
        text={_get(text, 'text')}
        images={imageSet.images.map(image => ({
          src: _get(image, 'file.url'),
          width: _get(image, 'file.details.image.width'),
          height: _get(image, 'file.details.image.height'),
          caption: {
            heading: image.title,
            text: image.description,
            centered: captionCentered,
          },
          orientation: imageSet.imageOrientation,
        }))}
        boxHeading={boxHeading}
        boxCta={{
          icon: boxCtaIconId,
          text: boxCtaText,
          url: boxCtaUrl,
        }}
      />
    )
  }
}

export const query = graphql`
  fragment imageSet on ContentfulImageSet {
    heading: tabTitle
    images {
      ...image
    }
    imageOrientation
  }

  fragment imageSetModule on ContentfulModuleImageSet {
    headingLarge: titleSize
    heading: title
    text: body {
      text: body
    }
    imageSet {
      ...imageSet
    }
    captionCentered: captionAlignment
    boxHeading: boxTitle
    boxCtaIconId
    boxCtaText
    boxCtaUrl
  }
`
