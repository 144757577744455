const getImageUrl = image => {
  if (typeof image === 'string') return image
  if (typeof image === 'object') return image && image.file && image.file.url
  return null
}

const getDescription = description => {
  if (typeof description === 'string') return description
  if (typeof description === 'object') return description && description.text
  return null
}

const extractMeta = ({
  metaTitle,
  pageTitle,
  metaDescription,
  metaImage,
  heroImage,
  pageImage,
}) => ({
  title: metaTitle || pageTitle,
  description: getDescription(metaDescription),
  image: getImageUrl(metaImage || pageImage || heroImage),
})

export default extractMeta
