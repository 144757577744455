import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import _get from 'lodash/get';

import VideoSteps, { Step } from 'components/video-steps';

export default class AnimatedListModule extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.object,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    videoFile: PropTypes.object,
    videoPoster: PropTypes.object,
    leaderCentered: PropTypes.bool,
    steps: PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
      heading: PropTypes.string,
      description: PropTypes.string,
      src: PropTypes.string,
    }),
    videoPosition: PropTypes.string,
  }

  render() {
    const {
      heading,
      text,
      ctaText,
      ctaUrl,
      videoFile,
      videoPoster,
      leaderCentered,
      steps,
      videoPosition,
    } = this.props;

    const hasSteps = _get(this.props, 'steps.items');

    return (
      <VideoSteps
        heading={heading}
        description={_get(text, 'text')}
        ctaText={ctaText}
        ctaUrl={ctaUrl}
        video={_get(videoFile, 'file.url')}
        poster={_get(videoPoster, 'file.url')}
        headingCentered={leaderCentered}
        videoPosition={videoPosition}
      >
        {hasSteps && steps.items.map((step, index) => (
          <Step
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            start={step.start}
            end={step.end}
            heading={step.heading}
            description={step.description}
            src={step.src}
          />
        ))}
      </VideoSteps>
    );
  }
}

export const query = graphql`
  fragment animatedListModule on ContentfulModuleAnimatedList {
    heading: title
    text: body {
      text: body
    }
    ctaText
    ctaUrl
    leaderCentered
    videoFile {
      file {
        url
      }
    }
    videoPoster {
      file {
        url
      }
    }
    videoPosition
    steps {
      items {
        start
        end
        heading
        description
        src
      }
    }
  }
`;
