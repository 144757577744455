import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import QuestionIcon from 'assets/svg/icons/question.svg';

import s from './Tooltip.scss';

export default class Tooltip extends Component {

  state = {
    active: false,
    overflow: false,
  }

  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: undefined,
  };

  onEnter = () => {
    const { x, y, width } = this.tooltipIcon.getBoundingClientRect() || 0;
    const windowWidth = window.innerWidth;
    const tooltipRect = this.tooltip.getBoundingClientRect();

    this.tooltip.style.left = `${Math.round(x + width)}px`;
    this.tooltip.style.top = `${Math.round(y)}px`;

    this.setState({
      active: true,
      overflow: (windowWidth < tooltipRect.right),
    });
  }

  onLeave = () => {
    this.setState({
      active: false,
      overflow: false,
    });
  }

  render() {
    const { active, overflow } = this.state;
    const { children } = this.props;

    return (
      <Fragment>
        <div
          ref={(el) => { this.tooltipIcon = el; }}
          className={s.tooltip__icon}
          onMouseEnter={this.onEnter}
          onMouseLeave={this.onLeave}
        >
          <QuestionIcon />
        </div>

        <div className={s('tooltip', { active, overflow })} ref={(el) => { this.tooltip = el; }}>
          <div className={s.tooltip__content}>{children}</div>
        </div>
      </Fragment>
    );
  }
}
