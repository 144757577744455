import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { graphql } from 'gatsby';
import IconSet from 'components/icon-set';

export default class IconSetModule extends Component {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
        contentType: PropTypes.string,
        details: PropTypes.shape({
          image: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
          }),
        }),
      }),
      description: PropTypes.string,
    }),
    iconBlocks: PropTypes.array,
    imagePlacement: PropTypes.oneOf(['top', 'middle']),
    columnCount: PropTypes.oneOf([1, 2, 3]),
    boxHeading: PropTypes.string,
    boxCtaIconId: PropTypes.string,
    boxCtaText: PropTypes.string,
    boxCtaUrl: PropTypes.string,
  }

  render() {
    const {
      heading,
      text,
      image,
      iconBlocks,
      imagePlacement,
      columnCount,
      boxHeading,
      boxCtaIconId,
      boxCtaText,
      boxCtaUrl,
    } = this.props;

    return (
      <IconSet
        heading={heading}
        text={_get(text, 'text')}
        image={{
          src: _get(image, 'file.url'),
          alt: _get(image, 'description'),
          width: _get(image, 'file.details.image.width'),
          height: _get(image, 'file.details.image.height'),
        }}
        iconBlocks={iconBlocks}
        imagePlacement={imagePlacement}
        columnCount={columnCount}
        boxHeading={boxHeading}
        boxCta={{
          icon: boxCtaIconId,
          text: boxCtaText,
          url: boxCtaUrl,
        }}
      />
    );
  }
}

export const query = graphql`
  fragment iconSetModule on ContentfulModuleIconSet {
    heading: title
    text: body {
      text: body
    }
    image {
      ...image
    }
    iconBlocks {
      ...submoduleList
    }
    imagePlacement
    columnCount
    boxHeading: boxTitle
    boxCtaIconId
    boxCtaText
    boxCtaUrl
  }
`;
