import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';

import s from './PriceCard.scss';

const PriceCard = ({ heading, price, text, cta }) => (
  <div className={s.priceCard}>
    <h3 className={s.priceCard__heading}>{heading}</h3>
    <div className={s.priceCard__price}>{price}</div>
    <p className={s.priceCard__text}>{text}</p>

    <div className={s.priceCard__cta}>
      <Button to={cta.url} color="red">{cta.text}</Button>
    </div>
  </div>
);

PriceCard.propTypes = {
  heading: PropTypes.string,
  price: PropTypes.string,
  text: PropTypes.string,
  cta: PropTypes.object,
};

export default PriceCard;
